// sm2Util.js
const sm4 = require('sm-crypto').sm4;
let key = 'dchealthSMFWd9a6' //g1PuR0tDkvo0N70cRvi1fg
let iv = 'DCH236u15Md8Ycg1' //qyU4xVulIrPmTH4zHEcnWQ==
import Sm4js from'sm4js'
import { encrypt, decrypt  }from './jsencrypt';

export const stringToHex = (str) => {
    let val = "";
    for (var i = 0; i < str.length; i++) {
        if (val == '') val = str.charCodeAt(i).toString(16);
        else val += str.charCodeAt(i).toString(16)
    }
    return val
}


let sm4Config = {
    key: stringToHex(key),
    iv: stringToHex(iv),
    mode: 'cbc',
    cipherType:'base64'
};

// 数据加密 用于axios请求拦截器
export const encryptSm4 = (requestParams = '') => {
    if (!requestParams) return
    let str = sm4.encrypt(requestParams, sm4Config.key, 1, sm4Config)
    return (str)
}

// 数据加密
export const setSm4 = (res) => {
    let SM4 = new Sm4js({
        key: key,
        iv: iv,
        mode: 'cbc',
        cipherType:'base64'
    })
    let str = SM4.encrypt(res)
    return (str)
}

// 数据解密 用于axios相应拦截器
export const decryptSm4 = (result = '') => {
    if (!result) return
    return sm4.decrypt(result, sm4Config.key, 1, sm4Config)
}

